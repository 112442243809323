import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="intersection"
export default class extends Controller {
  static classes = ["addable", "removable"];

  static values = {
    threshold: {
      default: 0.5,
      type: Number,
    },
    rootMargin: {
      default: "0px",
      type: String,
    },
  };

  initialize() {
    this.observer = new IntersectionObserver(this.call.bind(this), {
      threshold: this.thresholdValue,
      rootMargin: this.rootMarginValue,
    });
  }

  connect() {
    this.observer.observe(this.element);
  }

  call(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio <= 0) return;

      if (this.hasRemovableClass)
        this.element.classList.remove(...this.removableClasses);

      if (this.hasAddableClass)
        this.element.classList.add(...this.addableClasses);
    });
  }
}
