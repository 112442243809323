import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["openIcon", "closeIcon"];

  connect() {
    this.sidebarHidden =
      localStorage.getItem("hansa--sidebar-hidden") === "true";
    if (this.sidebarHidden) this.toggle();
  }

  // setState(hidden = false) {
  //   document.getElementById("sidebar").classList.toggle("hidden", hidden);
  //   this.openIconTarget.classList.toggle("hidden", !hidden);
  //   this.closeIconTarget.classList.toggle("hidden", hidden);
  // }

  toggle() {
    // User interface
    document.getElementById("sidebar").classList.toggle("hidden");
    this.openIconTarget.classList.toggle("hidden");
    this.closeIconTarget.classList.toggle("hidden");

    // State
    localStorage.setItem(
      "hansa--sidebar-hidden",
      document.getElementById("sidebar").classList.contains("hidden"),
    );
  }
}
