import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    open: Boolean,
  };

  close() {
    this.element.remove();
  }
}
